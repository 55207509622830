<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <div
            class="headingParagraph"
            style="font-family: Roboto Slab; display: flex"
          >
            Leaderboards
            <div class="titleLline"></div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" style="display: flex">
          <v-row>
            <v-col cols="5" sm="3" v-if="getCampaignType === 'district'">
              <v-sheet
                :class="[selectedTab ? 'activeSheet' : 'sheet']"
                :elevation="selectedTab ? 7 : 2"
                class="cardSheet"
                @click="selectedTab = true"
              >
                <v-row
                  :class="[$vuetify.breakpoint.smAndUp ? 'px-6 py-4' : 'px-4']"
                >
                  <v-col cols="3" sm="5">
                    <div>
                      <img
                        :src="
                          getSchoolImgUrl(
                            selectedTab
                              ? 'tabSchoolActive.svg'
                              : 'tabSchool.svg'
                          )
                        "
                        alt=""
                        class="CardImage"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="9"
                    sm="7"
                    :class="[$vuetify.breakpoint.smAndUp ? 'py-4' : 'px-4']"
                  >
                    <p class="CardText">School wide Leaderboard</p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col
              cols="5"
              sm="3"
              :class="[$vuetify.breakpoint.xsOnly ? 'ml-3' : '']"
            >
              <v-sheet
                :class="[!selectedTab ? 'activeSheet' : 'sheet']"
                :elevation="selectedTab ? 7 : 2"
                @click="selectedTab = false"
                class="cardSheet"
              >
                <v-row
                  :class="[$vuetify.breakpoint.smAndUp ? 'px-7 py-4' : 'px-4']"
                >
                  <v-col cols="3" sm="5">
                    <div>
                      <img
                        :src="
                          getClassImgUrl(
                            selectedTab ? 'tabClass.svg' : 'tabClassActive.svg'
                          )
                        "
                        alt=""
                        class="CardImage"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="9"
                    sm="7"
                    :class="[$vuetify.breakpoint.smAndUp ? 'pt-4' : 'px-4']"
                  >
                    <p class="CardText">Class wide Leaderboard</p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <br /><br />
      <div class="table-col">
        <v-data-table
          hide-default-footer
          :headers="getHeaders"
          :items="items"
          :loading="loading"
          loader-height="5"
          class="elevation-8"
          loading-text="Loading... Please wait"
          mobile-breakpoint="0"
        >
          <template v-slot:top v-if="$vuetify.breakpoint.xsOnly">
            <v-row justify="end">
              <v-col cols="12" class="py-6 pr-5 text-right">
                <v-menu transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="text-capitalize"
                      text
                      style="border: 1px solid #d30024"
                    >
                      <span style="color: #d30024">{{
                        tabs[selected].title
                      }}</span>
                      <v-icon right color="#D30024">
                        mdi-arrow-down-drop-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group v-model="selected">
                      <v-list-item v-for="(item, i) in tabs" :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
          <template v-slot:top v-else>
            <v-tabs
              background-color="transparent"
              color="#D30024"
              slider-size="3"
              v-model="selected"
              class="pt-5"
            >
              <v-tab
                v-for="(item, i) in tabs"
                :key="i"
                class="text-capitalize px-11"
                v-text="item.title"
                style="margin-left: 40px"
              >
              </v-tab>
            </v-tabs>
            <b><hr style="margin-bottom: 5px; color: #e5e5e5" /></b>
          </template>
          <template v-slot:item="props" v-if="selectedTab">
            <tr>
              <td class="text-center row-item">
                <div style="margin-bottom: -5px" v-if="props.item.rank == 1">
                  <img
                    height="25"
                    width="45"
                    src="@/assets/leaderBoardLogo.svg"
                  />
                </div>
                <div style="font-weight: 600">{{ props.item.rank }}</div>
              </td>
              <td class="text-center row-item py-6">
                <div
                  style="font-family: Lato; font-weight: 600; cursor: pointer"
                  @click="viewSchoolPublicDonationPageHandler(props.item.id)"
                >
                  {{ props.item.school_name }}
                </div>
              </td>

              <td class="text-center row-item" v-if="selected == 1">
                <div style="font-family: Lato; font-weight: 600">
                  {{ props.item.total_students_registered }}
                </div>
              </td>
              <td class="text-center row-item" v-else-if="selected == 2">
                <div style="font-family: Lato; font-weight: 600">
                  {{ props.item.wizfit_video }}
                </div>
              </td>
              <td class="text-center row-item" v-else-if="selected == 3">
                <div style="font-family: Lato; font-weight: 600">
                  {{ props.item.total_shares }}
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:item="props" v-else>
            <tr>
              <td class="text-center row-item">
                <div style="margin-bottom: -5px" v-if="props.item.rank == 1">
                  <img
                    height="25"
                    width="45"
                    src="@/assets/leaderBoardLogo.svg"
                  />
                </div>
                <div style="font-weight: 600">{{ props.item.rank }}</div>
              </td>
              <td class="text-center row-item">
                <div
                  style="font-family: Lato; font-weight: 600; cursor: pointer"
                  @click="viewClassPublicDonationPageHandler(props.item.id)"
                >
                  {{ props.item.school_name }}
                </div>
                <div
                  style="font-weight: 600; color: #1d1d1d; cursor: pointer"
                  @click="viewClassPublicDonationPageHandler(props.item.id)"
                >
                  {{ props.item.grade_teacher }}
                </div>
                <div
                  style="color: #777777; font-size: 12px cursor: pointer;"
                  @click="viewClassPublicDonationPageHandler(props.item.id)"
                >
                  Grade {{ props.item.grade_title }}
                </div>
              </td>

              <td class="text-center row-item" v-if="selected == 1">
                <div style="font-family: Lato; font-weight: 600">
                  {{ props.item.total_students_registered }}
                </div>
              </td>
              <td class="text-center row-item" v-else-if="selected == 2">
                <div style="font-family: Lato; font-weight: 600">
                  {{ props.item.wizfit_video }}
                </div>
              </td>
              <td class="text-center row-item" v-else-if="selected == 3">
                <div style="font-family: Lato; font-weight: 600">
                  {{ props.item.total_shares }}
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <br />
    <div v-if="$vuetify.breakpoint.smAndUp"><br /><br /><br /></div>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { mapGetters } from "vuex";
import {
  API_ADMIN_DASHBOARD_SCHOOL_LEADERBOARD,
  API_PUBLIC_DONATION_PAGE_CLASS_LEADERBOARD,
} from "@/constants/APIUrls";
import Helper from "@/helper";
export default {
  name: "LeaderboardTable",
  data() {
    return {
      tabs: [
        { title: "Most Funds Raised", sort_by: "total_fund_raised" },
        { title: "Top Registration", sort_by: "total_students_registered" },
        { title: "Most Video Watched", sort_by: "total_videos_watched" },
        { title: "Most Number of Shares", sort_by: "total_shares" },
      ],
      headers: [
        {
          width: "25%",
          text: "Rank",
          align: "center",
          sortable: false,
          value: "rank",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          text: "School",
          align: "center",
          sortable: false,
          value: "school",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      schoolHeaders: [
        {
          width: "25%",
          text: "Rank",
          align: "center",
          sortable: false,
          value: "rank",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          text: "Class",
          align: "center",
          sortable: false,
          value: "class",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          width: "27%",
          sortable: false,
          text: "Fund Raised",
          value: "fund",
          class: ["tableHeader-text", "tableHeader-bg"],
          align: "center",
        },
      ],
      selected: 0,
      selectedTab: true,
      loading: false,
      items: [],
      detailCount: 5,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    getCampaignType() {
      return this.getCampaign.campaign_detail.campaign_type;
    },
    getHeaders() {
      if (this.selectedTab) {
        return this.headers;
      } else {
        return this.schoolHeaders;
      }
    },
  },
  watch: {
    selectedTab() {
      if (this.selectedTab) {
        this.schoolHeaderManipulation();
      } else {
        this.classHeaderManipulation();
      }
      this.getLeaderboard();
    },
    selected: function () {
      if (this.selectedTab) {
        this.schoolHeaderManipulation();
      } else {
        this.classHeaderManipulation();
      }
      this.getLeaderboard();
    },
  },
  methods: {
    getSchoolImgUrl(url) {
      return require("@/assets/publicDonationPage/" + url);
    },
    getClassImgUrl(url) {
      return require("@/assets/publicDonationPage/" + url);
    },
    mountingMethods() {
      if (this.getCampaignType === "school") {
        this.selectedTab = false;
      }
    },
    getLeaderboard() {
      this.loading = true;
      const successHandler = (res) => {
        this.storeResponse(res);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      if (this.getCampaignType === "school") {
        formData["school_id"] = this.getCampaignDetail.campaign_detail.school;
      }
      formData["sorting_key"] = this.tabs[this.selected].sort_by;
      formData["detail_count"] = this.detailCount;
      if (this.selectedTab) {
        Axios.request_GET(
          API_ADMIN_DASHBOARD_SCHOOL_LEADERBOARD,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        Axios.request_GET(
          API_PUBLIC_DONATION_PAGE_CLASS_LEADERBOARD,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    storeResponse(response) {
      if (this.selectedTab) {
        this.items = [];
        let i = 1;
        for (let obj of response.data.school_list) {
          let item_obj = {
            rank: i,
            district_name: obj.district_name,
            percentage_wizfit_video: obj.percentage_wizfit_video,
            school_logo: obj.school_logo,
            school_name: obj.school_name,
            total_fund_raised: obj.original_total_fund_raised,
            total_shares: obj.total_shares,
            total_students_registered: obj.total_students_registered,
            total_wizfit_video: obj.total_wizfit_video,
            wizfit_video: obj.wizfit_video,
            id: obj.id,
          };
          this.items.push(item_obj);
          i++;
          console.log("total_fund_raisedddddd", obj.total_fund_raised_50);
        }
      } else {
        this.items = [];
        let i = 1;
        for (let obj of response.data.class_list) {
          let item_obj = {
            rank: i,
            grade_teacher: obj.grade_teacher,
            grade_title: obj.grade_title,
            percentage_wizfit_video: obj.percentage_wizfit_video,
            school_name: obj.school_name,
            total_fund_raised: obj.total_fund_raised_50,
            total_shares: obj.total_shares,
            total_students_registered: obj.total_students_registered,
            total_wizfit_video: obj.total_wizfit_video,
            wizfit_video: obj.wizfit_video,
            id: obj.id,
          };
          this.items.push(item_obj);
          i++;
        }
      }
      this.loading = false;
      console.log(this.items);
    },
    viewSchoolPublicDonationPageHandler(school) {
      const queryParam = "school=" + school;
      Helper.redirectToPublicDonationPage("", queryParam);
    },
    viewClassPublicDonationPageHandler(teacher) {
      const queryParam = "teacher=" + teacher;
      Helper.redirectToPublicDonationPage("", queryParam);
    },
    resetHeader() {
      this.headers = [
        {
          width: "25%",
          text: "Rank",
          align: "center",
          sortable: false,
          value: "rank",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          text: "School",
          align: "center",
          sortable: false,
          value: "school",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        // {
        //   width: "27%",
        //   sortable: false,
        //   text: "Fund Raised3",
        //   value: "fund",
        //   class: ["tableHeader-text", "tableHeader-bg"],
        //   align: "center",
        // },
      ];
      this.schoolHeaders = [
        {
          width: "25%",
          text: "Rank",
          align: "center",
          sortable: false,
          value: "rank",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          text: "Class",
          align: "center",
          sortable: false,
          value: "class",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        // {
        //   width: "27%",
        //   sortable: false,
        //   text: "Fund Raised4",
        //   value: "fund",
        //   class: ["tableHeader-text", "tableHeader-bg"],
        //   align: "center",
        // },
      ];
    },
    schoolHeaderManipulation() {
      this.resetHeader();
      switch (this.selected) {
        case 0:
          console.log("total_fund_raised");
          break;
        case 1:
          this.headers.push({
            width: "27%",
            sortable: false,
            text: "# Student Registered",
            value: "fund",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          });
          console.log("total_students_registered");
          break;
        case 2:
          this.headers.push({
            width: "27%",
            sortable: false,
            text: "Wizfit Video",
            value: "fund",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          });
          console.log("total_videos_watched");
          break;
        case 3:
          this.headers.push({
            width: "27%",
            sortable: false,
            text: "# of Shares",
            value: "fund",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          });
          console.log("total_shares");
          break;
        default:
          break;
      }
    },
    classHeaderManipulation() {
      this.resetHeader();
      switch (this.selected) {
        case 0:
          break;
        case 1:
          this.schoolHeaders.push({
            width: "27%",
            sortable: false,
            text: "# Student Registered",
            value: "fund",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          });
          console.log("total_students_registered");
          break;
        case 2:
          this.schoolHeaders.push({
            width: "27%",
            sortable: false,
            text: "Wizfit Video",
            value: "fund",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          });
          console.log("total_videos_watched");
          break;
        case 3:
          this.schoolHeaders.push({
            width: "27%",
            sortable: false,
            text: "# of Shares",
            value: "fund",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          });
          console.log("total_shares");
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.mountingMethods();
    setTimeout(() => {
      this.getLeaderboard();
    }, 10);
  },
};
</script>
<style scoped>
.active-class {
  background-color: #521597;
  color: #ffffff;
}
.headingParagraph {
  font-weight: 600;
  font-style: normal;
  font-size: 25px;
  line-height: 140%;
  place-content: center;
  /* identical to box height, or 45px */

  color: #2c1963;
}
.v-input.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #d30024 !important;
}

.v-data-table.elevation-8.v-data-table--has-top.theme--light {
  font-family: Lato;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 3px 5px 21px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.sheet.v-sheet {
  border-radius: 8px;
  cursor: pointer;
  background-color: #ececec;
  color: #757575;
}
.activeSheet.v-sheet {
  border-radius: 8px;
  background-color: #521597;
  color: #ffffff;
  cursor: pointer;
}
.titleLline {
  height: 4px;
  width: 50px;
  background-color: #2c1963;
  margin-left: 10px;
  margin-top: 18px;
  border-radius: 20px;
}
.cardSheet.v-sheet {
  height: 130px;
  width: 280px;
}
.CardText {
  font-family: Roboto Slab;
  font-weight: 700;
  font-size: 20px;
}
.CardImage {
  width: 97%;
  height: 80px;
}
@media only screen and (max-width: 600px) {
  .table-col {
    overflow-x: scroll;
  }
  .v-data-table.elevation-8.v-data-table--has-top.theme--light {
    font-family: Lato;
    overflow-x: hidden;
    min-width: 330px;
  }
  .headingParagraph {
    font-size: 20px;
  }
  .titleLline {
    width: 20px;
    height: 3px;
    margin-top: 15px;
  }
  .cardSheet.v-sheet {
    width: 150px;
    height: 60px;
    border-radius: 4px;
  }
  .CardText {
    font-size: 12px;
  }
  .CardImage {
    height: 35px;
    width: 35px;
  }
}
</style>
<style>
* {
  font-family: Lato;
}
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}
</style>
